<template>
  <!-- 个人信息页背景 -->
  <div class="back">
    <!-- 个人信息容器 -->
    <div class="userinfo">
      <!-- 标题 -->
      <div class="title">个人信息</div>
      <!-- 个人信息表单 -->
      <el-form ref="dynamicValidateForm" :model="Form" :rules="rules">
        <el-form-item label="邮箱" style="color: rgba(255, 255, 255, 0.9);opacity: 0.6;">
          <el-input :disabled="true" v-model="email"></el-input>
        </el-form-item>
        <el-form-item label="品牌" style="color: rgba(255, 255, 255, 0.9);opacity: 0.6;">
          <el-input :disabled="true" v-model="company_name"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="department">
          <el-select
            @change="selectDepartment"
            v-model="Form.department"
            placeholder="请选择部门"
            popper-class="choose_option"
          >
            <el-option
              v-for="item in this.departmentList"
              :key="item.id"
              :label="item.department"
              :value="item.department"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-select v-model="Form.position" placeholder="请选择职位" popper-class="choose_option">
            <el-option
              v-for="item in this.positionList"
              :key="item.id"
              :label="item.department"
              :value="item.department"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="Form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item size="large">
          <el-button type="primary" @click="submitForm('dynamicValidateForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      // 表单验证对象
      rules: {
        department: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        position: [{ required: true, message: "请选择职位", trigger: "blur" }],
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      },
      // 部门数据对象
      departmentList: [],
      // 职位数据对象
      positionList: [],
      Form: {
        department: this.$store.state.userInfo.department,
        position: this.$store.state.userInfo.position,
        name: this.$store.state.userInfo.username,
      },
    };
  },
  methods: {
    // 保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证在此提交个人信息
        if (valid) {
          let formData = new FormData();
          formData.append("true_name", this.Form.name);
          formData.append("department", this.Form.department);
          formData.append("position", this.Form.position);
          this.$http({
            method: "POST",
            url: "accounts/complete_userinfo",
            data: formData,
          }).then((res) => {
            //  提交成功
            if (res.data.status == 0) {
              // 提交到vuex中
              this.$store.commit("setUserInfo", {
                username: this.Form.name,
                department: this.Form.department,
                position: this.Form.position
              });
              // 跳转主页
              this.$router.push("/");
            }
          }).catch(error => console.log(error));
        } else {
          // 没通过验证返回错误，不进行下一步
          return false;
        }
      });
    },

    // 请求部门数据
    getDepartment() {
      this.$http({
        method: "GET",
        url: "accounts/get_departments",
        params: { company_name: this.company_name },
      }).then((res) => {
        this.departmentList = res.data.data;
      }).catch(error => console.log(error));
    },
    // 请求职位数据
    getPosition() {
      this.$http({
        method: "GET",
        url: "accounts/get_positions",
        params: { department: this.Form.department },
      }).then((res) => {
        this.positionList = res.data.data;
      }).catch(error => console.log(error));
    },
    // 选择部门
    selectDepartment() {
      this.Form.position=''
      // 请求职位数据
      this.getPosition();
    },
  },
  created() {
    // 请求部门数据
    this.getDepartment();
    // 请求职位数据
    this.getPosition();
  },
  computed: {
    email() {
      return this.$store.state.email;
    },
    company_name() {
      return this.$store.state.company_name;
    },
  },
  watch:{
    '$store.state.userInfo': {
      deep: true,
      handler: function(newValue, oldValue) {
        this.Form.department = newValue.department
        this.Form.position = newValue.position
        this.Form.name = newValue.username

      }
    }
  }
};
</script>

<style lang="scss" scoped >
// 个人信息页背景
.back {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  //   个人信息容器
  .userinfo {
    margin-top: 15vh;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
      padding: 0 0 59px 75px;
    }
    /deep/.el-form-item {
      display: flex;
    }
    /deep/.el-form-item__label {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      opacity: 0.6;
      width: 75px;
      letter-spacing: 15px;
    }
    /deep/.el-input {
      width: 388px;
      height: 40px;
    }
    /deep/.el-input__inner {
      background: transparent;
      border-radius: 5px;
      opacity: 0.6;
      color: #fff;
      border: 1px solid #5e5f72;
    }
    /deep/.el-form-item__label:before {
      content: "";
      color: #f56c6c;
      margin-right: 0px;
    }
    /deep/.el-select-dropdown {
      background-color: blue;
    }
    /deep/.el-form-item__error {
      top: 32%;
      left: 21vw;
      width: 80px;
    }
    .el-button--primary {
      width: 388px;
      height: 40px;
      margin-left: 75px;
      margin-top: 38px;
    }
  }
}
</style>

<style lang="scss">
// 全局的下拉选择框样式
.el-select-dropdown.choose_option{
    background: #32313e;
    border: 1px solid #5e5f72;
    opacity: 1;
    margin-top: 5px;
  .popper__arrow {
    display: none;
  }
 .popper__arrow {
    background: #32313e;
  }
  // .el-select-dropdown {

  // }
  .el-select-dropdown__item {
    color: rgba(255, 255, 255, 0.9);
    opacity: 1;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #292833;
  }
  .el-scrollbar__wrap {
    overflow: scroll;
    height: 154px;
  }

}

</style>
